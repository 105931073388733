import type { FunctionComponent, TouchEvent } from 'react';
import React, {
  useRef,
  useCallback,
  useEffect,
  Children,
  cloneElement,
  useState,
} from 'react';

import { Icon } from 'src/components/shared';

import style from './Carousel.module.scss';
import { DIRECTIONS, EXTRA_OFFSET, THRESHOLD } from './constants';
import CustomPagination from './CustomPagination';
import type { CarouselProps } from './types';

import { mergeClassNames } from 'src/utils/ReactUtils';

const Carousel: FunctionComponent<CarouselProps> = ({
  animationDuration = 1000,
  autoPlay = false,
  autoPlayInterval = 2000,
  children,
  className,
  customPagination,
  edgesClassName,
  hasArrows = true,
  hasPagination = true,
  hasRightEdgeFade = false,
  isStretched = false,
  nextArrow,
  nextArrowClassName,
  onSlideChanged,
  prevArrow,
  prevArrowClassName,
  slidesPerView = 1,
  activeCustomPagination,
  customPaginationContainer,
  customPaginationItemClass,
  slideBy = 1,
  dataTestId,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentDirection, setCurrentDirection] = useState(DIRECTIONS.FORWARD);
  const [isAnimationOn, setIsAnimationOn] = useState(false);
  const touchStart = useRef(0);
  const touchEnd = useRef(-1);
  const slideElementRef = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);
  const scrollContainerRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeItem = scrollContainerRef.current.querySelector(
        `.${style.active}`,
      ) as HTMLElement | null;
      if (activeItem) {
        const container = scrollContainerRef.current;
        const itemOffset = activeItem.getBoundingClientRect().left;
        const containerOffset = container.getBoundingClientRect().left;
        const scrollPosition =
          itemOffset -
          containerOffset +
          container.scrollLeft -
          container.offsetWidth / 2 +
          activeItem.offsetWidth / 2;
        container.scrollTo({ left: scrollPosition, behavior: 'smooth' });
      }
    }
  }, [activeIndex]);

  const animatedSliding = useCallback(
    (direction: string, position: number) => {
      if (!ready) {
        setReady(true);
      }

      setActiveIndex(position);
      setCurrentDirection(direction);
      setIsAnimationOn(true);
      setTimeout(() => {
        setIsAnimationOn(false);
      }, 50);

      onSlideChanged?.(slideBy > 1 ? position + (slideBy - 1) : position);
    },
    [
      slideBy,
      setActiveIndex,
      setCurrentDirection,
      setIsAnimationOn,
      onSlideChanged,
      ready,
    ],
  );

  const nextSlide = useCallback(() => {
    const numberOfItems = React.Children.count(children);
    const position =
      activeIndex >= numberOfItems - 1 ? 0 : activeIndex + slideBy;

    const stretchedPosition = ready
      ? position
      : slideBy > 1
      ? position - (slideBy - 1)
      : position - 1;

    animatedSliding(
      DIRECTIONS.FORWARD,
      isStretched || slideBy > 1 ? stretchedPosition : position,
    );
  }, [animatedSliding, slideBy, activeIndex, children, ready, isStretched]);

  const prevSlide = useCallback(() => {
    const numberOfItems = React.Children.count(children);

    const position =
      activeIndex === 0 ? numberOfItems - 1 : activeIndex - slideBy;

    animatedSliding(DIRECTIONS.BACKWARD, position);
  }, [animatedSliding, slideBy, activeIndex, children]);

  const onTouchStart = useCallback((event: TouchEvent<HTMLDivElement>) => {
    const { targetTouches } = event;

    touchStart.current = targetTouches[0].clientX;
  }, []);

  const onTouchMove = useCallback((event: TouchEvent<HTMLDivElement>) => {
    const { targetTouches } = event;
    touchEnd.current = targetTouches[0].clientX;
  }, []);

  const onTouchEnd = useCallback(() => {
    if (touchEnd.current === -1) return;

    const isSwipingLeft = touchStart.current - touchEnd.current > THRESHOLD;

    if (isSwipingLeft) {
      nextSlide();
    }

    const isSwipingRight = touchStart.current - touchEnd.current < -THRESHOLD;

    if (isSwipingRight) {
      prevSlide();
    }
  }, [touchStart, touchEnd, nextSlide, prevSlide]);

  useEffect(() => {
    if (!autoPlay) return;

    const interval = setInterval(() => {
      nextSlide();
    }, autoPlayInterval);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const childrenList = Children.map(children, (child) => <>{child}</>);

  const getTransformValue = () => {
    const offset = ready ? slideElementRef.current?.offsetWidth || 0 : 0;

    if (isStretched && !isAnimationOn)
      return `translateX(${
        offset > 0 ? -offset + EXTRA_OFFSET : EXTRA_OFFSET
      }px)`;

    if (!isAnimationOn)
      return ready && slidesPerView > slideBy && slideBy > 1
        ? `translateX(-${offset * slideBy}px)`
        : `translateX(calc(-100% / ${slidesPerView}))`;

    const translateValue = isStretched
      ? `-${offset * 2}px`
      : slidesPerView > slideBy && slideBy > 1
      ? `-${offset * slideBy * 2}px`
      : `calc(-200% / ${slidesPerView})`;
    return currentDirection === DIRECTIONS.BACKWARD
      ? `translateX(${translateValue})`
      : `translateX(${isStretched ? EXTRA_OFFSET : 0}px)`;
  };

  const getOrder = (index: number) => {
    const numItems = Children.count(children);

    return (index - activeIndex + numItems) % numItems;
  };

  const renderSlides = (child: JSX.Element, index: number) => (
    <div
      ref={index === 0 ? slideElementRef : undefined}
      style={{
        flex: isStretched ? '1' : `1 0 calc(100% / ${slidesPerView})`,
        order: getOrder(isStretched ? index : index + 1),
      }}
      key={index}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {child}
    </div>
  );

  const renderArrows = () => {
    if (!hasArrows) return null;

    if (hasArrows && prevArrow && nextArrow) {
      return (
        <>
          {cloneElement(prevArrow, {
            onClick: prevSlide,
            className: mergeClassNames([
              style.prevArrow,
              prevArrow.props.className,
            ]),
          })}

          {cloneElement(nextArrow, {
            onClick: nextSlide,
            className: mergeClassNames([
              style.nextArrow,
              nextArrow.props.className,
            ]),
          })}
        </>
      );
    }

    return (
      <>
        <Icon
          className={mergeClassNames([style.nextArrow, nextArrowClassName])}
          onClick={nextSlide}
          width={24}
          height={24}
          name="fleche-g"
          dataTestid="next_arrow"
        />
        {ready && (
          <Icon
            className={mergeClassNames([style.prevArrow, prevArrowClassName])}
            onClick={prevSlide}
            width={24}
            height={24}
            name="fleche-g"
            dataTestid="next_arrow"
          />
        )}
      </>
    );
  };

  const renderPagination = () => {
    if (!hasPagination) return null;

    if (customPagination)
      return (
        <CustomPagination
          {...{
            customPagination,
            activeIndex,
            animatedSliding,
            activeCustomPagination,
            customPaginationItemClass,
          }}
        />
      );

    return (
      <ul
        className={mergeClassNames([
          style.paginationDots,
          customPaginationContainer,
        ])}
      >
        {React.Children.map(children, (_, index) => (
          <li
            data-testid={`pagination_dot_${index}`}
            className={mergeClassNames([
              customPaginationItemClass,
              `${
                index === activeIndex
                  ? mergeClassNames([style.activeSlide, activeCustomPagination])
                  : ''
              }`,
            ])}
            onClick={() => {
              const dir =
                index < activeIndex ? DIRECTIONS.BACKWARD : DIRECTIONS.FORWARD;
              animatedSliding(dir, index);
            }}
          ></li>
        ))}
      </ul>
    );
  };

  if (childrenList?.length === 1) {
    return renderSlides(childrenList[0], 0);
  }

  return (
    <div className={style.carousel} data-testid={dataTestId ?? 'carousel'}>
      {hasRightEdgeFade && (
        <div
          className={mergeClassNames([style.fadeEffect, edgesClassName])}
        ></div>
      )}
      <div className={mergeClassNames([style.carouselWrapper, className])}>
        <div
          style={{
            transition: isAnimationOn
              ? 'none'
              : `transform ${animationDuration}ms ease`,
            transform: getTransformValue(),
          }}
          className={style.carouselContainer}
        >
          {childrenList?.map(renderSlides)}
        </div>
      </div>
      {renderPagination()}
      {renderArrows()}
    </div>
  );
};

export default Carousel;
