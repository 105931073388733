import React, { useRef, useEffect } from 'react';

import style from './Carousel.module.scss';
import { DIRECTIONS } from './constants';

import { mergeClassNames } from 'src/utils/ReactUtils';

type CustomPaginationProps = {
  customPagination: React.ReactNode[];
  activeIndex: number;
  animatedSliding: (direction: string, position: number) => void;
  activeCustomPagination?: string;
  customPaginationItemClass?: string;
};

const CustomPagination: React.FunctionComponent<CustomPaginationProps> = ({
  customPagination,
  activeIndex,
  animatedSliding,
  activeCustomPagination,
  customPaginationItemClass,
}) => {
  const scrollContainerRef = useRef<HTMLUListElement>(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  // Centring logic on the active pagination item
  useEffect(() => {
    if (scrollContainerRef.current) {
      const activeItem = scrollContainerRef.current.children[
        activeIndex
      ] as HTMLElement;
      if (activeItem) {
        const containerWidth = scrollContainerRef.current.offsetWidth;
        const itemWidth = activeItem.offsetWidth;
        const itemPosition = activeItem.offsetLeft;
        const offset = (containerWidth - itemWidth) / 2;
        scrollContainerRef.current.scrollLeft = itemPosition - offset;
      }
    }
  }, [activeIndex]);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!scrollContainerRef.current) return;
    e.preventDefault();

    isDragging.current = true;
    startX.current = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft.current = scrollContainerRef.current.scrollLeft;

    scrollContainerRef.current.style.cursor = 'grabbing';
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging.current || !scrollContainerRef.current) return;

    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const draggingSensitivity = 2;
    const walk = (x - startX.current) * draggingSensitivity;

    scrollContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUp = () => {
    if (!scrollContainerRef.current) return;

    isDragging.current = false;
    scrollContainerRef.current.style.cursor = 'grab';
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseup', handleMouseUp);
    container.addEventListener('mouseleave', handleMouseUp);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseup', handleMouseUp);
      container.removeEventListener('mouseleave', handleMouseUp);
    };
  }, []);

  return (
    <ul
      ref={scrollContainerRef}
      className={mergeClassNames([style.customPaginationCarousel])}
      onMouseDown={handleMouseDown}
    >
      {customPagination.map((item, index) => (
        <li
          key={index}
          className={mergeClassNames([
            ...(index === activeIndex
              ? [style.active, activeCustomPagination]
              : []),
            customPaginationItemClass,
          ])}
          onClick={() => {
            const dir =
              index < activeIndex ? DIRECTIONS.BACKWARD : DIRECTIONS.FORWARD;
            animatedSliding(dir, index);
          }}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default CustomPagination;
